import React, { Component } from 'react'

class NotFound extends React.Component {

    render() {
        return (
            <div className='flex w-full justify-center my-20 items-center'>NotFound</div>
        )
    }
}

export default NotFound