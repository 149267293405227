import React from 'react'
import { SiFarcaster } from "react-icons/si";
import { SiGithub } from "react-icons/si";
import { RiTwitterXLine } from "react-icons/ri";
import Farcaster from "../images/transparent-black.png"
import { LuArrowUpRight } from "react-icons/lu";
import { Link } from "react-router-dom"
import terms from "../pages/TermsConditions"

const Basement = () => {
  return (

    <div id="mobile" className='w-full flex items-center justify-center lg:mt-10 lg:bottom-0 lg:fixed'>
      <div className='w-full flex-row md:hidden justify-center bg-black'>
        <div className='w-full px-8 py-4 flex-row mt-2 justify-between items-center'>
          <div className='flex-row ps-2 text-white bg-black text-md'>
            <Link to="https://docs.cinematiclabs.xyz/" target='_blank' className='mb-2 flex cursor-pointer'>Documents</Link>
            <Link to="/terms" className='mb-2 flex cursor-pointer'>Terms & Conditions</Link>

          </div>
          <div className='flex'>
            <div className='flex-row mt-4 ps-2 text-white text-md'>
              <Link hidden='true' to="" target="_blank"><div className='items-center mb-2 justify-start flex hover:underline hover:decoration-solid hover:underline-offset-1'>Twiiter<LuArrowUpRight size="14" className='' /></div></Link>
              <Link to="https://warpcast.com/~/channel/cinematic" target="_blank"><div className='items-center mb-2 justify-start flex hover:underline hover:decoration-solid hover:underline-offset-1'>Farcaster<LuArrowUpRight size="14" className='' /></div></Link>
              <Link to="https://github.com/cinematiceth" target="_blank"> <div className='items-center mb-2 justify-start flex hover:underline hover:decoration-solid hover:underline-offset-1'>Github<LuArrowUpRight size="14" className='' /></div></Link>
            </div>
          </div>

          <div className='flex mt-4 mb-2 ms-2 text-sm text-cyan-300'>Version: v0.1.beta.5.2024 <span className='ms-1 text-violet-200'>Cinematic Labs</span></div>
        </div></div>

      <div id="desktop" className='w-full hidden md:flex-row py-1 bg-black md:flex justify-between items-center'>
        <div className='flex ps-2 text-white bg-black text-[0.7rem]'>
          <Link to="https://docs.cinematiclabs.xyz/" target='_blank' className='me-4 mb-1 flex cursor-pointer border-b border-black/0 hover:underline hover:decoration-solid hover:underline-offset-1'>Documents</Link>
          <Link to="/terms" className='me-4 mb-1 flex cursor-pointer border-b border-black/0 hover:underline hover:decoration-solid hover:underline-offset-1'>Terms & Conditions</Link>

          <div className='flex text-[0.7rem] text-cyan-300'>Version: v0.1.beta.5.2024 <span className='ms-1 text-violet-200'>Cinematic Labs</span></div>
          <div className='hidden text-xs text-white bg-black px-1 w-fit'>We don't collect cookies, IPs, tracking, tags, etc.</div>
        </div>
        <div className='flex text-white 0py-2 md:pe-2 0my-2 md:mt-0 text-[0.7rem] bg-black'>
          <Link hidden='true' to="" target="_blank"><div className='ms-4 items-center justify-center flex hover:underline hover:decoration-solid hover:underline-offset-1'>Twiiter<LuArrowUpRight size="14" className='' /></div></Link>
          <Link to="https://warpcast.com/~/channel/cinematic" target="_blank"><div className='ms-4 items-center justify-center flex hover:underline hover:decoration-solid hover:underline-offset-1'>Farcaster<LuArrowUpRight size="14" className='' /></div></Link>
          <Link to="https://github.com/cinematiceth" target="_blank"> <div className='ms-4 items-center justify-center flex hover:underline hover:decoration-solid hover:underline-offset-1'>Github<LuArrowUpRight size="14" className='' /></div></Link>
        </div>
      </div>
    </div>
  )
}

export default Basement