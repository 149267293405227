import React, { useState } from 'react'
import { HiMiniViewfinderCircle } from "react-icons/hi2";
import { BsPersonFillCheck } from "react-icons/bs";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Link } from 'react-router-dom';
import { useWeb3Modal } from '@web3modal/ethers/react'
import fuelimage from "../images/Fuel_Logo_Black_Monochrome_RGB.png"

const Head = ({ web3Handler, account, address }) => {

  const { open } = useWeb3Modal()
  const [nav, setNav] = useState(false)

  const handleNav = () => {
    setNav(!nav)
  }

  return (
    <div className='w-full flex items-center justify-center'>
      <div className='w-full max-w-[1200px] px-8 py-4 flex justify-between items-center border-b border-gray-200'>
        <div className='flex text-black py-2 items-center justify-start text-4xl'>
          <HiMiniViewfinderCircle size="30" className='me-1 mt-2' /> <Link to='/'>Cinematic
            <span className='text-xs bg-gradient-to-r from-cyan-300 to-violet-200 text-black py-0.25 px-1 rounded-sm align-text-top ms-1 mt-4'>beta</span></Link></div>
        <div id="menu" className='flex'>
          <div className='md:flex hidden lg:me-2 items-center'>

          <div id="fuel" className='py-1 px-8 text-sm h-fit font-light bg-black/5 rounded-lg text-black/30 mx-1 cursor-default'>
                <div className='flex'>Storyboard</div>
              </div>
            <Link to="https://fuel.cinematiclabs.xyz/" target='_blank' className='hidden'>
              <div id="fuel" className='hidden py-1 px-4 text-sm h-fit font-light bg-[#00f58c]/50 hover:bg-[#00f58c] ease-in-out duration-300 rounded-lg text-black/70 mx-1 cursor-pointer'>
                <div className='flex items-center'><img src={fuelimage} className=' h-[11px] w-fit opacity-70' /></div><div className='flex ms-1'>Testnet</div>
              </div>
            </Link>
            <Link to="../taiko/trail" className='hidden'>
            <div className='md:flex hidden py-1 px-8 text-sm h-fit font-light bg-gradient-to-r from-pink-400 to-rose-400 rounded-lg text-white mx-1 cursor-pointer ease-in-out duration-300'>
              Taiko Trail</div>
            </Link>
            <div className='flex mx-1'>
              {!address ? (
                <button onClick={() => open()} className='md:flex hidden justify-end rounded-lg bg-black/80 text-white/90 lg:me-4 py-1 px-8 text-sm hover:bg-black font-light'>Connect Wallet</button>)
                : (
                  <button onClick={() => open()} className='md:flex hidden text-black/90 items-center rounded-lg justify-end border border-black/70  lg:me-4 py-1 px-4 text-sm font-light bg-gradient-to-r from-cyan-100 to-violet-100'>
                    <BsPersonFillCheck size="14" className='me-2 text-black/70' />  {address.slice(0, 6) + '...' + address.slice(38, 42)}</button>)
              }
            </div>
          </div>


          <div onClick={handleNav} className='flex md:hidden'>
            {!nav ? <AiOutlineMenu size={32} /> : <AiOutlineClose size={32} />}
          </div>


        </div>
      </div>

      <div onClick={handleNav} className={!nav ? 'fixed left-[-100%]' : 'md:hidden fixed text-black backdrop-blur-sm left-0 top-0 w-full border-r border-r-gray-900 h-full bg-black/50 ease-in-out duration-100'}>
        <div onClick={(e) => {
          e.stopPropagation();
        }} className={!nav ? 'fixed left-[-100%]' : 'md:hidden w-auto fixed items-start backdrop-blur-sm top-auto left-4 right-4 justify-center rounded-xl mx-4 h-auto bottom-4 mb-14 bg-white'}>

          
          <ul className={!nav ? 'hidden' : 'block pt-12 list-none justify-center'}>
            <li id="fuel" className='hidden cursor-pointer p-6 m-auto'>
              <Link to="https://fuel.cinematiclabs.xyz/" target='_blank' className='w-full'>
                <div className='inline-flex w-full py-2 px-4 text-lg h-fit items-center justify-center font-light bg-[#00f58c]/50 hover:bg-[#00f58c] ease-in-out duration-300 rounded-lg text-black/70 mx-1 cursor-pointer'>
                  <div className='inline-flex items-center clear-right justify-end'><img src={fuelimage} className=' h-[20px] w-fit opacity-70 clear-right' /></div><div className='inline-flex ms-1 justify-start'>Testnet</div>
                </div>
              </Link>
            </li>
            <li id="taiko" className='hidden cursor-pointer p-6 m-auto'>
              <Link to="/taiko/trail">
              <div className='inline-block  md:hidden w-full py-2 items-center justify-center text-center px-8 text-lg h-fit font-light  bg-gradient-to-r from-pink-400 to-rose-400  rounded-lg text-white mx-1 cursor-pointer'>
                Taiko Trail</div>
              </Link>
            </li>
            <li className='cursor-pointer p-6 m-auto'>
              {!address ? (
                <button onClick={() => open()} className='md:hidden inline-block  w-full items-center rounded-lg justify-center  bg-black/80 text-white/90 lg:me-4 py-2 px-8 text-lg hover:bg-black font-light'>Connect Wallet</button>)
                : (
                  <button onClick={() => open()} className='inline-block md:hidden w-full text-black/90 items-center rounded-lg justify-center border border-black/70  lg:me-4 py-2 px-4 text-lg font-light bg-gradient-to-r from-cyan-100 to-violet-100'>
                      {address.slice(0, 6) + '...' + address.slice(38, 42)}</button>)
              }
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Head