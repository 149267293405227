import React, { Component } from 'react'
import Item from './Item';

export class ItemsMain extends Component {
  render() {
    return (
      <div className='flex flex-wrap w-full justify-start ms-2 md:ms-0'>
        {this.props.items.map(el => (
            <Item key={el.id} item={el} account={this.props.account} />
        ))}

      </div>
    )
  }
}

export default ItemsMain