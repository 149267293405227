import React, { useState, useEffect } from 'react'
import { ethers } from 'ethers';
import { Link } from 'react-router-dom';
import { useWeb3ModalProvider, useWeb3ModalAccount, useWeb3Modal } from '@web3modal/ethers/react'
import { FaCircleCheck, FaCloudShowersWater } from "react-icons/fa6";
import TaikoABI from '../TaikoTrailABI.json'
import TaikoERC20 from '../TaikoTokenABI.json'
import TaikoAirdropABI from '../TaikoAirdropABI.json'
import NFT from '../NFT.json'


const TaikoTrail = ({ loadBlockchainData, web3Handler, account, provider, setProvider, network, setNetwork }) => {
  const [isMinting, setIsMinting] = useState(false);
  const [nft, setNFT] = useState(null);
  const [txHash, setTXHASH] = useState(null);
  const [isPermited, setIsPermited] = useState(false);
  const [isTaikoStarted, setIsTaikoStarted] = useState(false); // if user mint started NFT
  const [isAgreedOldMan, setIsAgreedOldMan] = useState(false); // if user Agreed to help to Old Man
  const [isBoatTicket, setIsBoatTicket] = useState(false); // if user buy the boat ticket
  const [isClaimAirdrop, setIsClaimAirdrop] = useState(false); // check airdrop claiming
  const [isClaimTaikoBalance, setIsClaimTaikoBalance] = useState(false); // check Taiko balance
  const [isClaimCinematic, setIsClaimCinematic] = useState(false); // check Cinematic NFTs
  const [isClaimTxCount, setIsClaimTxCount] = useState(false); //check if user has more than 3 transactions
  const [isClaimTxClaimed, setIsClaimTxClaimed] = useState(false); //check if user claimed the box
  const [isClaimTaikoons, setIsClaimTaikoons] = useState(false); //check if user has Taikoons NFT
  const [isClaim1Eth, setIsClaim1Eth] = useState(false); //check if balance > 1 ETH
  const [isClaimFarcaster, setIsClaimFarcaster] = useState(false); //check Farcaster
  const [isVesselMaximum, setIsVesselMaximum] = useState(false);
  const [isVesselDone, setIsVesselDone] = useState(false); //if user collect 3 boxes
  const [isNft1, setIsNft1] = useState(false);
  const [isNft2, setIsNft2] = useState(false);
  const [isNft3, setIsNft3] = useState(false);
  const [isAidropClaimed, setIsAirdropClaimed] = useState(false);
  const [isBalanceOverOneEth, setIsBalanceOverOneEth] = useState(false);
  const [isTransactionCount, setTransactionCount] = useState(0);
  const [isTaikoBalance, setIsTaikoBalance] = useState(0);
  const [isMinted, setIsMinted] = useState(false);

  const { open } = useWeb3Modal()
  const { address, chainId, isConnected } = useWeb3ModalAccount()
  const { walletProvider } = useWeb3ModalProvider()

  const TaikoRpc = 'https://taiko.blockpi.network/v1/rpc/d9a5d21a7fa7df6f67f058fa451ac8f775bd1fbb'
  const contract = '0xb4295201674Fd2fB22Ac74C0880b7d0136F76aD7'
  const TaikoChainCode = '167000n'
  const TaikoChainHex = '0x28c58'
  const tokenAddress = '0xA9d23408b9bA935c230493c40C73824Df71A0975';
  const ipfs = 'https://ipfs.io/ipfs/QmTC1UqLaQfTo6YZZySoTHiQKv666TchGXByoDyN1beeGZ'
  const priceTaikoTrail = 0.0001
  const claimFee = 0.00003

  const checkStarted = async () => {
    if (address) {
      try {
        const provider = new ethers.JsonRpcProvider(TaikoRpc);
        const TaikoContract = new ethers.Contract(contract, TaikoABI, provider);
        const parapStarted = await TaikoContract.isStarted(address);
        setIsTaikoStarted(parapStarted);
      }
      catch (e) {
        console.error(e);
      }
    }
  }

  // Check claimable statuses
  const checkClaimStatuses = async () => {

    try {
      const provider = new ethers.JsonRpcProvider(TaikoRpc);
      const TaikoContract = new ethers.Contract(contract, TaikoABI, provider);
      setNetwork(TaikoChainCode);
      const [paramAgreed, paramTicket, paramAirdrop, paramBalance, paramCinematic, paramTxCount, paramTaikoons, param1Eth, paramFarcaster] = await Promise.all([
        TaikoContract.isAgreed(address),
        TaikoContract.isTicket(address),
        TaikoContract.isClaimTaikoAirdrop(address),
        TaikoContract.isClaimTaikoBalance(address),
        TaikoContract.isClaimCinematic(address),
        TaikoContract.isClaimTx(address),
        TaikoContract.isClaimTaikoons(address),
        TaikoContract.isClaimEthBalance(address),
        TaikoContract.isClaimFarcaster(address)
      ]);

      setIsAgreedOldMan(paramAgreed);
      setIsBoatTicket(paramTicket);
      setIsClaimAirdrop(paramAirdrop);
      setIsClaimTaikoBalance(paramBalance);
      setIsClaimCinematic(paramCinematic);
      setIsClaimTxCount(paramTxCount);
      setIsClaimTaikoons(paramTaikoons);
      setIsClaim1Eth(param1Eth);
      setIsClaimFarcaster(paramFarcaster);
    } catch (e) {
      console.error(e);
    }

  };

  const checkVesselDone = async () => {

    let vesselTotal = 0;

    if (isClaimAirdrop) {
      vesselTotal += 1;
    }
    if (isClaimTaikoBalance) {
      vesselTotal += 1;
    }
    if (isClaimCinematic) {
      vesselTotal += 1;
    }
    if (isClaim1Eth) {
      vesselTotal += 1;
    }
    if (isClaimTxCount) {
      vesselTotal += 1;
    }
    if (isClaimTaikoons) {
      vesselTotal += 1;
    }
    if (isClaimFarcaster) {
      vesselTotal += 1;
    }
    //console.log('Vessel: ', vesselTotal);//

    if (vesselTotal > 2) {
      setIsVesselDone(true);
    } else if (vesselTotal === 7) {
      setIsVesselMaximum(true);
    } else {
      setIsVesselDone(false);
      setIsVesselMaximum(false);
    }

  }

  const permitCinematic = async () => {

    const nft1 = '0x87a871998c90a7f7a5058c6e40308d77a98a5232';
    const nft2 = '0xcb4d62a406bc090e11a7f9ba73517a2afb155734';
    const nft3 = '0xbc371f3daa4a76062da6752a0313b86378b0064f';

    try {
      const provider = new ethers.JsonRpcProvider(TaikoRpc);
      //const provider = new ethers.JsonRpcProvider('https://rpc.mainnet.taiko.xyz');
      const nftContract1 = new ethers.Contract(nft1, NFT, provider);
      const nftContract2 = new ethers.Contract(nft2, NFT, provider);
      const nftContract3 = new ethers.Contract(nft3, NFT, provider);

      const [mintedNft1, mintedNft2, mintedNft3] = await Promise.all([
        nftContract1.hasMinted(address),
        nftContract2.hasMinted(address),
        nftContract3.hasMinted(address)
      ]);

      setIsNft1(mintedNft1);
      setIsNft2(mintedNft2);
      setIsNft3(mintedNft3);
    } catch (e) {
      console.error('Error checking minting status for NFTs:', e);
    }

  };

  const permitBalance = async () => {

    try {
      const provider = new ethers.JsonRpcProvider(TaikoRpc);
      const balance = await provider.getBalance(address);
      const balanceInEth = ethers.formatEther(balance);
      //console.log(balanceInEth); //
      if (parseFloat(balanceInEth) > 0.025) {
        setIsBalanceOverOneEth(true);
      } else {
        setIsBalanceOverOneEth(false);
      }
    } catch (error) {
      console.error(error);
    }

  };

  const permitTxCount = async () => {

    try {
      const provider = new ethers.JsonRpcProvider(TaikoRpc);
      const count = await provider.getTransactionCount(address);
      setTransactionCount(count);
      //console.log(count); //
    } catch (error) {
      console.error(error);
    }

  };

  const permitTaikoBalance = async () => {

    try {
      const provider = new ethers.JsonRpcProvider(TaikoRpc);
      const contract = new ethers.Contract(tokenAddress, TaikoERC20, provider);
      const balance = await contract.balanceOf(address);
      const balanceInTaiko = ethers.formatUnits(balance, 18);
      setIsTaikoBalance(balanceInTaiko);
      //console.log(balanceInTaiko); //
    } catch (error) {
      console.error(error);
    }

  };

  const permitClaimStatus = async () => {

    try {
      const response = await fetch(`https://trailblazer.hekla.taiko.xyz/api/address?address=${address}`);
      const data = await response.json();
      const value = data.value;
      //console.log('Airdrop', value)
      if (value > 0) {
        setIsAirdropClaimed(true);
      }
    } catch (error) {
      console.error('Error:', error);
    }

  }

  const checkMintStatus = async () => {
    try {
      const provider = new ethers.JsonRpcProvider(TaikoRpc);
      const TaikoContract = new ethers.Contract(contract, TaikoABI, provider);
      setNetwork(TaikoChainCode);
      const hasMinted = await TaikoContract.isMinted(address);
      setIsMinted(hasMinted);
      //console.log(hasMinted);
    }
    catch (e) {
      console.error(e);
    }
  };


  const changeNetwork = async () => {
    const params = [TaikoChainHex];
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: TaikoChainHex }]
      });
      return true;
    } catch (err) {
      if (err.code === 4902) {
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params
          });
          return true;
        } catch (error) {
          console.error(error);
          return false;
        }
      } else {
        console.error(err);
        return false;
      }
    }
  };

  const submitTaiko = async (e) => {
    e.preventDefault();
    setIsMinting(true);

    const provider = new ethers.BrowserProvider(walletProvider);
    setProvider(provider);
    const network = await provider.getNetwork();
    const currentChainId = parseInt(network.chainId, 16);

    if (currentChainId !== TaikoChainCode) {
      const networkChanged = await changeNetwork();
      if (!networkChanged) {
        console.error("Network change required to continue. Please switch to the correct network.");
        setIsMinting(false);
        return;
      }
    }
    setIsMinting(true);
    try {
      const txHash = await mintTaikoTrail()
      setIsMinting(false);
      checkMintStatus();
      //console.log(txHash);
    }
    catch (e) {
      console.error(e);
      setIsMinting(false);
    }
    return
  }

  const mintTaikoTrail = async () => {
    try {
      const provider = new ethers.BrowserProvider(walletProvider);
      setProvider(provider)
      console.log(provider)
      const network = await provider.getNetwork()
      const nft = new ethers.Contract(contract, TaikoABI, provider);
      setNFT(nft);
      setIsMinting(true);
      const signer = await provider.getSigner()
      console.log(signer)
      const transaction = await nft.connect(signer).mint(ipfs, { value: ethers.parseUnits(`${priceTaikoTrail}`, "ether") })
      await transaction.wait()

      const txHash = transaction.hash;
      setTXHASH(txHash)

      return txHash
    }
    catch (e) {
      console.error(e);
      return null;
    }
  }

  const submitClaim = async (e, claimFunctionName) => {
    e.preventDefault();
    setIsMinting(true);

    const provider = new ethers.BrowserProvider(walletProvider);
    setProvider(provider);
    const network = await provider.getNetwork();
    const currentChainId = parseInt(network.chainId, 16);

    if (currentChainId !== TaikoChainCode) {
      const networkChanged = await changeNetwork();
      if (!networkChanged) {
        console.error("Network change required to continue. Please switch to the correct network.");
        setIsMinting(false);
        return;
      }
    }
    setIsMinting(true);
    try {
      const txHash = await claim(claimFunctionName)
      setIsMinting(false);
      //console.log(txHash);
    }
    catch (e) {
      console.error(e);
      setIsMinting(false);
    }
    return
  }

  const claim = async (claimFunctionName) => {
    try {
      const provider = new ethers.BrowserProvider(walletProvider);
      setProvider(provider)
      //console.log(provider)
      const network = await provider.getNetwork()
      const claim = new ethers.Contract(contract, TaikoABI, provider);
      setIsMinting(true);
      const signer = await provider.getSigner()
      const transaction = await claim.connect(signer)[claimFunctionName]({ value: ethers.parseUnits(`${claimFee}`, "ether") })
      await transaction.wait()
      const txHash = transaction.hash;
      setTXHASH(txHash)
      return txHash
    }
    catch (e) {
      console.error(e);
      return null;
    }
  }



  useEffect(() => {
    const interval10s = setInterval(async () => {
      try {
        await checkStarted();
      } catch (error) {
        console.error('Error during 30s interval execution:', error);
      }
    }, 5000); // 5 seconds

    const interval30s = setInterval(async () => {
      try {
        await checkClaimStatuses();
      } catch (error) {
        console.error('Error during 30s interval execution:', error);
      }
    }, 15000); // 15 seconds

    const interval60s = setInterval(async () => {
      try {
        await permitCinematic();
        await permitBalance();
        await permitTxCount();
        await permitTaikoBalance();
        await permitClaimStatus();
      } catch (error) {
        console.error('Error during 60s interval execution:', error);
      }
    }, 30000); // 30 seconds

    return () => {
      clearInterval(interval10s);
      clearInterval(interval30s);
      clearInterval(interval60s);
    };
  }, []);



  useEffect(() => {
    checkVesselDone();
  }, [isClaimAirdrop, isClaimTaikoBalance, isClaimCinematic, isClaim1Eth, isClaimTxCount, isClaimTaikoons, isClaimFarcaster]);

  useEffect(() => {
    checkMintStatus();
  }, [isMinted, isVesselDone, isVesselMaximum]);


  return (
    <div className='flex w-full justify-center my-10'>
      <div className='flex lg:hidden m-10 text-black text-lg h-screen'>Please use a device with a screen resolution higher than 1024x768.</div>
      

      {!isPermited ? (
        <div className='hidden lg:w-[1000px] xl:w-[1280px] lg:flex items-center justify-center'>
          <div id='body' className='flex w-full justify-center items-center'>
            <div className='flex w-full justify-center mb-6 relative'>

              <div id="start ping" className="animate-ping absolute lg:top-[780px] xl:top-[1000px] inline-flex h-10 w-10 rounded-full bg-[#ff5d8f] opacity-75"></div>
              {isTaikoStarted ? (<div id="old farmer ping" className="animate-ping absolute lg:end-[250px] lg:top-[1000px] xl:end-[320px] xl:top-[1320px] inline-flex h-10 w-10 rounded-full bg-[#ff5d8f] opacity-75"></div>) : null}
              {isAgreedOldMan ? (<div id="boat ticket ping" className="animate-ping absolute lg:start-[280px] lg:top-[1070px] xl:start-[370px] xl:top-[1400px] inline-flex h-10 w-10 rounded-full bg-[#ff5d8f] opacity-75"></div>) : null}
              {isClaimAirdrop || (isAgreedOldMan && isAidropClaimed) ? (<div id="grab1 ping" className="animate-ping absolute lg:start-[400px] lg:top-[1300px] xl:start-[520px] xl:top-[1720px] inline-flex h-10 w-10 rounded-full bg-[#ff5d8f] opacity-75"></div>) : null}
              {isClaimCinematic || (isAgreedOldMan && isNft1 && isNft2 && isNft3) ? (<div id="grab2 ping" className="animate-ping absolute lg:start-[260px] lg:top-[1400px] xl:start-[320px] xl:top-[1850px] inline-flex h-10 w-10 rounded-full bg-[#ff5d8f] opacity-75"></div>) : null}
              {isClaimTaikoBalance || (isAgreedOldMan && isTaikoBalance > 0) ? (<div id="grab3 ping" className="animate-ping absolute lg:start-[600px] lg:top-[1420px] xl:start-[780px] xl:top-[1850px] inline-flex h-10 w-10 rounded-full bg-[#ff5d8f] opacity-75"></div>) : null}
              {isClaim1Eth || (isAgreedOldMan && isBalanceOverOneEth) ? (<div id="grab4 ping" className="animate-ping absolute lg:end-[220px] lg:top-[1480px] xl:end-[300px] xl:top-[1930px] inline-flex h-10 w-10 rounded-full bg-[#ff5d8f] opacity-75"></div>) : null}
              {isClaimTxCount || (isAgreedOldMan && isTransactionCount >= 7) ? (<div id="grab5 ping" className="animate-ping absolute lg:start-[100px] lg:top-[1750px] xl:start-[150px] xl:top-[2230px] inline-flex h-10 w-10 rounded-full bg-[#ff5d8f] opacity-75"></div>) : null}
              {isAgreedOldMan && isClaimTaikoons ? (<div id="grab6 ping" className="animate-ping absolute lg:end-[280px] lg:top-[1700px] xl:end-[370px] xl:top-[2200px] inline-flex h-10 w-10 rounded-full bg-[#ff5d8f] opacity-75"></div>) : null}
              {isAgreedOldMan && isClaimFarcaster ? (<div id="farcaster ping" className="animate-ping absolute lg:end-[370px] lg:top-[1950px] xl:end-[450px] xl:top-[2500px] inline-flex h-10 w-10 rounded-full bg-[#ff5d8f] opacity-75"></div>) : null}
              {isBoatTicket ||
                isClaimAirdrop ||
                isClaimTaikoBalance ||
                isClaimCinematic ||
                isClaim1Eth ||
                isClaimTxCount ||
                isClaimTaikoons ||
                isClaimFarcaster ? (<div id="vessel ping" className="animate-ping absolute lg:start-[370px] lg:top-[2350px] xl:start-[450px] xl:top-[3000px] inline-flex h-10 w-10 rounded-full bg-[#ff5d8f] opacity-75"></div>) : null}
              {isVesselDone ? (<div id="end ping" className="animate-ping absolute lg:start-[520px] lg:top-[2820px] xl:start-[650px] xl:top-[3650px] inline-flex h-10 w-10 rounded-full bg-cyan-300 opacity-75"></div>) : null}

              <div id="start block" className='absolute lg:top-[650px] xl:top-[950px] bg-white/90 drop-shadow-md cursor-default rounded-2xl opacity-0 hover:opacity-100 ease-in-out duration-300 w-[400px] h-fit'>
                <div className='flex w-full text-xl text-black justify-center my-4'>Welcome to the Village</div>
                <div className='flex'>
                  <div className='flex w-1/2 justify-start ms-10'><img src="../img/taiko-element-start.png" className='w-[150px] h-fit rounded-2xl' /></div>
                  <div className='flex-row mx-4 text-sm'>
                    <div className='flex'>Begin your Taiko Trail by getting this magical plaque</div>
                    <div className='flex mt-1'>Once you are able to get it, the trail will be open for discovery</div>
                  </div>
                </div>
                {!address ? (
                  <div className='flex m-6'>
                    <button onClick={() => open()} className=' bg-black hover:bg-[#ea638c] ease-in-out duration-300 text-white w-full py-1 rounded-lg justify-center'>Start the Trail</button>
                  </div>
                ) : !isTaikoStarted && !isMinting ? (
                  <div className='flex m-6'>
                    <button onClick={(e) => submitClaim(e, 'start')} className=' bg-black hover:bg-[#ea638c] ease-in-out duration-300 text-white w-full py-1 rounded-lg justify-center'>Start the Trail</button>
                  </div>
                ) : !isTaikoStarted && isMinting ? (
                  <div className='flex m-6'>
                    <button disabled={isMinting} className=' bg-black/10 text-black/20 w-full py-1 rounded-lg justify-center'>Processing</button>
                  </div>
                ) : (
                  <div className='flex m-6 justify-center items-center'>
                    <FaCircleCheck size="20" className=' text-emerald-500' /><span className='ms-3 text-black/60 text-lg'>Started</span>
                  </div>)
                }
              </div>

              {isTaikoStarted ? (
                <div id="old farmer block" className='absolute lg:top-[950px] lg:end-[80px] xl:top-[1270px] xl:end-[200px] bg-white/90 cursor-default drop-shadow-md rounded-2xl opacity-0 hover:opacity-100 ease-in-out duration-300 w-[400px] h-fit'>
                  <div className='flex w-full text-xl text-black justify-center my-4'>Old Farmer</div>
                  <div className='flex mb-6'>
                    <div className='flex w-1/3 justify-start ms-6'><img src="../img/taiko-element-oldman.png" className='w-[150px] h-fit rounded-2xl' /></div>
                    <div className='flex-row w-2/3 mx-4 text-sm'>
                      <div className='flex'>Hello, stranger. I see you're holding a magic plaque. Good choice. Sometimes we don't know what awaits us on our journey. If it's not too much trouble, could you help me carry my boxes of goods to the boat?</div>
                      {!address ? (
                        <div className='flex mt-3'>
                          <button onClick={() => open()} className=' bg-black hover:bg-[#ea638c] ease-in-out duration-300 text-white w-full py-1 rounded-lg justify-center'>Agree</button>
                        </div>
                      ) : !isAgreedOldMan && !isMinting ? (
                        <div className='flex mt-3'>
                          <button onClick={(e) => submitClaim(e, 'agree')} className=' bg-black hover:bg-[#ea638c] ease-in-out duration-300 text-white w-full py-1 rounded-lg justify-center'>Agree</button>
                        </div>
                      ) : !isAgreedOldMan && isMinting ? (
                        <div className='flex mt-3'>
                          <button disabled={isMinting} className=' bg-black/10 text-black/20 w-full py-1 rounded-lg justify-center'>Processing</button>
                        </div>
                      ) : (
                        <div className='flex mt-3 justify-center items-center'>
                          <FaCircleCheck size="20" className=' text-emerald-500' /><span className='ms-3 text-black/60 text-lg'>Agreed</span>
                        </div>)}
                    </div>
                  </div>
                </div>) : null}

              {isAgreedOldMan ? (
                <div id="boat ticket block" className='absolute lg:top-[1000px] lg:start-[120px] xl:top-[1350px] xl:start-[220px] bg-white/90 cursor-default drop-shadow-md rounded-2xl opacity-0 hover:opacity-100 ease-in-out duration-300 w-[300px] h-fit'>
                  <div className='flex w-full text-xl text-black justify-center my-4'>Boat Tickets</div>
                  <div className='flex mb-6'>
                    <div className='flex w-1/2 justify-start ms-6'><img src="../img/taiko-element-ticket.png" className='w-[150px] h-fit rounded-2xl' /></div>
                    <div className='flex-row mx-4 text-sm'>
                      <div className='flex'>Buy a boat ticket and bring all boxes on board</div>
                      {!address ? (
                        <div className='flex mt-3'>
                          <button onClick={() => open()} className=' bg-black hover:bg-[#ea638c] ease-in-out duration-300 text-white w-full py-1 rounded-lg justify-center'>Get a Ticket</button>
                        </div>)
                        : !isBoatTicket && !isMinting ? (
                          <div className='flex mt-3'>
                            <button onClick={(e) => submitClaim(e, 'ticket')} className=' bg-black hover:bg-[#ea638c] ease-in-out duration-300 text-white w-full py-1 rounded-lg justify-center'>Get a Ticket</button>
                          </div>
                        ) : !isBoatTicket && isMinting ? (
                          <div className='flex mt-3'>
                            <button disabled={isMinting} className=' bg-black/10 text-black/20 w-full py-1 rounded-lg justify-center'>Processing</button>
                          </div>
                        ) : (
                          <div className='flex mt-3 justify-center items-center'>
                            <FaCircleCheck size="20" className=' text-emerald-500' /><span className='ms-3 text-black/60 text-lg'>Done</span>
                          </div>)}
                    </div>
                  </div>
                </div>) : null}

              {isClaimAirdrop || (isAgreedOldMan && isAidropClaimed) ? (
                <div id="grab1 block" className='absolute lg:top-[1260px] lg:start-[330px] xl:top-[1660px] xl:start-[500px] bg-white/90 cursor-default drop-shadow-md rounded-2xl opacity-0 hover:opacity-100 ease-in-out duration-300 w-[200px] h-fit'>
                  <div className='flex w-full text-xl text-black justify-center my-4'>Taiko Airdrop 📦</div>
                  <div className='flex w-full my-4'>
                    {!address ? (
                      <button onClick={() => open()} id="grab1 button" className='bg-black hover:bg-[#ea638c] ease-in-out duration-300 text-white w-full py-1 mx-4 rounded-lg justify-center'>Claim</button>
                    ) : !isClaimAirdrop && !isMinting ? (
                      <button onClick={(e) => submitClaim(e, 'claimTaikoAirdrop')} id="grab1 button" className='bg-black hover:bg-[#ea638c] ease-in-out duration-300 text-white w-full py-1 mx-4 rounded-lg justify-center'>Claim</button>
                    ) : !isClaimAirdrop && isMinting ? (
                      <button disabled={isMinting} id="grab1 button" className='bg-black/10 text-black/20 w-full py-1 mx-4 rounded-lg justify-center'>Processing</button>
                    ) : (
                      <button disabled={true} id="grab1 button" className='bg-black/10 text-black/20 w-full py-1 mx-4 rounded-lg items-center justify-center flex'><FaCircleCheck size="14" className='me-1 text-black/15' />Claimed</button>
                    )}
                  </div>
                </div>
              ) : null}

              {isClaimCinematic || (isAgreedOldMan && isNft1 && isNft2 && isNft3) ? (
                <div id="grab2 block" className='absolute lg:top-[1360px] lg:start-[150px] xl:top-[1800px] xl:start-[300px] bg-white/90 cursor-default drop-shadow-md rounded-2xl opacity-0 hover:opacity-100 ease-in-out duration-300 w-[200px] h-fit'>
                  <div className='flex w-full text-xl text-black justify-center my-4'>Cinematic 📦</div>
                  <div className='flex w-full my-4'>
                    {!address ? (
                      <button onClick={() => open()} id="grab1 button" className='bg-black hover:bg-[#ea638c] ease-in-out duration-300 text-white w-full py-1 mx-4 rounded-lg justify-center'>Claim</button>
                    ) : !isClaimCinematic && !isMinting ? (
                      <button onClick={(e) => submitClaim(e, 'claimCinematic')} id="grab1 button" className='bg-black hover:bg-[#ea638c] ease-in-out duration-300 text-white w-full py-1 mx-4 rounded-lg justify-center'>Claim</button>
                    ) : !isClaimCinematic && isMinting ? (
                      <button disabled={isMinting} id="grab1 button" className='bg-black/10 text-black/20 w-full py-1 mx-4 rounded-lg justify-center'>Processing</button>
                    ) : (
                      <button disabled={true} id="grab1 button" className='bg-black/10 text-black/20 w-full py-1 mx-4 rounded-lg items-center justify-center flex'><FaCircleCheck size="14" className='me-1 text-black/15' />Claimed</button>
                    )}
                  </div>
                </div>
              ) : null}

              {isClaimTaikoBalance || (isAgreedOldMan && isTaikoBalance > 0) ? (
                <div id="grab3 block" className='absolute lg:top-[1380px] lg:start-[500px] xl:top-[1830px] xl:start-[700px] bg-white/90 cursor-default drop-shadow-md rounded-2xl opacity-0 hover:opacity-100 ease-in-out duration-300 w-[200px] h-fit'>
                  <div className='flex w-full text-xl text-black justify-center my-4'>Taiko Balance 📦</div>
                  <div className='flex w-full my-4'>
                    {!address ? (
                      <button onClick={() => open()} id="grab1 button" className='bg-black hover:bg-[#ea638c] ease-in-out duration-300 text-white w-full py-1 mx-4 rounded-lg justify-center'>Claim</button>
                    ) : !isClaimTaikoBalance && !isMinting ? (
                      <button onClick={(e) => submitClaim(e, 'claimTaikoBalance')} id="grab1 button" className='bg-black hover:bg-[#ea638c] ease-in-out duration-300 text-white w-full py-1 mx-4 rounded-lg justify-center'>Claim</button>
                    ) : !isClaimTaikoBalance && isMinting ? (
                      <button disabled={isMinting} id="grab1 button" className='bg-black/10 text-black/20 w-full py-1 mx-4 rounded-lg justify-center'>Processing</button>
                    ) : (
                      <button disabled={true} id="grab1 button" className='bg-black/10 text-black/20 w-full py-1 mx-4 rounded-lg items-center justify-center flex'><FaCircleCheck size="14" className='me-1 text-black/15' />Claimed</button>
                    )}
                  </div>
                </div>
              ) : null}

              {isClaim1Eth || (isAgreedOldMan && isBalanceOverOneEth) ? (
                <div id="grab4 block" className='absolute lg:top-[1450px] lg:end-[90px] xl:top-[1900px] xl:end-[190px] bg-white/90 cursor-default drop-shadow-md rounded-2xl opacity-0 hover:opacity-100 ease-in-out duration-300 w-[200px] h-fit'>
                  <div className='flex w-full text-xl text-black justify-center my-4'>Secret 📦</div>
                  <div className='flex w-full my-4'>
                    {!address ? (
                      <button onClick={() => open()} id="grab1 button" className='bg-black hover:bg-[#ea638c] ease-in-out duration-300 text-white w-full py-1 mx-4 rounded-lg justify-center'>Claim</button>
                    ) : !isClaim1Eth && !isMinting ? (
                      <button onClick={(e) => submitClaim(e, 'claimEthBalance')} id="grab1 button" className='bg-black hover:bg-[#ea638c] ease-in-out duration-300 text-white w-full py-1 mx-4 rounded-lg justify-center'>Claim</button>
                    ) : !isClaim1Eth && isMinting ? (
                      <button disabled={isMinting} id="grab1 button" className='bg-black/10 text-black/20 w-full py-1 mx-4 rounded-lg justify-center'>Processing</button>
                    ) : (
                      <button disabled={true} id="grab1 button" className='bg-black/10 text-black/20 w-full py-1 mx-4 rounded-lg items-center justify-center flex'><FaCircleCheck size="14" className='me-1 text-black/15' />Claimed</button>
                    )}
                  </div>
                </div>
              ) : null}

              {isClaimTxCount || (isAgreedOldMan && isTransactionCount >= 7) ? (
                <div id="grab5 block" className='absolute top-[1730px] start-[50px] xl:top-[2200px] xl:start-[100px] bg-white/90 cursor-default drop-shadow-md rounded-2xl opacity-0 hover:opacity-100 ease-in-out duration-300 w-[200px] h-fit'>
                  <div className='flex w-full text-xl text-black justify-center my-4'>Secret 📦</div>
                  <div className='flex w-full my-4'>
                    {!address ? (
                      <button onClick={() => open()} id="grab1 button" className='bg-black hover:bg-[#ea638c] ease-in-out duration-300 text-white w-full py-1 mx-4 rounded-lg justify-center'>Claim</button>
                    ) : !isClaimTxCount && !isMinting ? (
                      <button onClick={(e) => submitClaim(e, 'claimTx')} id="grab1 button" className='bg-black hover:bg-[#ea638c] ease-in-out duration-300 text-white w-full py-1 mx-4 rounded-lg justify-center'>Claim</button>
                    ) : !isClaimTxCount && isMinting ? (
                      <button disabled={isMinting} id="grab1 button" className='bg-black/10 text-black/20 w-full py-1 mx-4 rounded-lg justify-center'>Processing</button>
                    ) : (
                      <button disabled={true} id="grab1 button" className='bg-black/10 text-black/20 w-full py-1 mx-4 rounded-lg items-center justify-center flex'><FaCircleCheck size="14" className='me-1 text-black/15' />Claimed</button>
                    )}
                  </div>
                </div>
              ) : null}

              {isAgreedOldMan && isClaimTaikoons ? (
                <div id="grab6 block" className='absolute lg:top-[1660px] lg:end-[180px] xl:top-[2160px] xl:end-[300px] bg-white/90 cursor-default drop-shadow-md rounded-2xl opacity-0 hover:opacity-100 ease-in-out duration-300 w-[200px] h-fit'>
                  <div className='flex w-full text-xl text-black justify-center my-4'>Taikoons 📦</div>
                  <div className='flex w-full my-4'>
                    {!address ? (
                      <button onClick={() => open()} id="grab1 button" className='bg-black hover:bg-[#ea638c] ease-in-out duration-300 text-white w-full py-1 mx-4 rounded-lg justify-center'>Claim</button>
                    ) : !isClaimTaikoons && !isMinting ? (
                      <button onClick={(e) => submitClaim(e, 'claimTaikoons')} id="grab1 button" className='bg-black hover:bg-[#ea638c] ease-in-out duration-300 text-white w-full py-1 mx-4 rounded-lg justify-center'>Claim</button>
                    ) : !isClaimTaikoons && isMinting ? (
                      <button disabled={isMinting} id="grab1 button" className='bg-black/10 text-black/20 w-full py-1 mx-4 rounded-lg justify-center'>Processing</button>
                    ) : (
                      <button disabled={true} id="grab1 button" className='bg-black/10 text-black/20 w-full py-1 mx-4 rounded-lg items-center justify-center flex'><FaCircleCheck size="14" className='me-1 text-black/15' />Claimed</button>
                    )}
                  </div>
                </div>
              ) : null}

              {isAgreedOldMan && isClaimFarcaster ? (
                <div id="farcaster block" className='absolute lg:top-[1900px] lg:end-[250px] xl:top-[2460px] xl:end-[350px] bg-white/90 cursor-default drop-shadow-md rounded-2xl opacity-0 hover:opacity-100 ease-in-out duration-300 w-[200px] h-fit'>
                  <div className='flex w-full text-xl text-black justify-center my-4'>Farcaster 📦</div>
                  <div className='flex w-full my-4'>
                    {!address ? (
                      <button onClick={() => open()} id="grab1 button" className='bg-black hover:bg-[#ea638c] ease-in-out duration-300 text-white w-full py-1 mx-4 rounded-lg justify-center'>Claim</button>
                    ) : !isClaimFarcaster && !isMinting ? (
                      <button onClick={(e) => submitClaim(e, 'claimFarcaster')} id="grab1 button" className='bg-black hover:bg-[#ea638c] ease-in-out duration-300 text-white w-full py-1 mx-4 rounded-lg justify-center'>Claim</button>
                    ) : !isClaimFarcaster && isMinting ? (
                      <button disabled={isMinting} id="grab1 button" className='bg-black/10 text-black/20 w-full py-1 mx-4 rounded-lg justify-center'>Processing</button>
                    ) : (
                      <button disabled={true} id="grab1 button" className='bg-black/10 text-black/20 w-full py-1 mx-4 rounded-lg items-center justify-center flex'><FaCircleCheck size="14" className='me-1 text-black/15' />Claimed</button>
                    )}
                  </div>
                </div>
              ) : null}

              {isBoatTicket ||
                isClaimAirdrop ||
                isClaimTaikoBalance ||
                isClaimCinematic ||
                isClaim1Eth ||
                isClaimTxCount ||
                isClaimTaikoons ||
                isClaimFarcaster ? (
                <div id="vessel block" className='absolute lg:top-[2220px] lg:start-[320px] xl:top-[2850px] xl:start-[430px] bg-white/90 cursor-default drop-shadow-md rounded-2xl opacity-0 hover:opacity-100 ease-in-out duration-300 lg:w-[500px] xl:w-[640px] h-fit'>
                  {!isVesselDone ? (<>
                    <div className='flex w-full lg:text-2xl xl:text-3xl text-black justify-center my-8'>Good Job 👍</div>
                    <div className='flex w-full lg:text-md xl:text-lg text-black justify-start my-2 px-8'>But you have to take at least 3 boxes to load them on the boat. These are your boxes. Try to find more. Good luck to you!</div></>)
                    : (<>
                      <div className='flex w-full lg:text-2xl xl:text-3xl text-black justify-center my-8'>Congratulation 🎉🎉🎉</div>
                      <div className='flex w-full lg:text-md xl:text-lg text-black justify-start my-2 px-8'>You have almost all the boxes to load the vessel. You've worked hard. These are your boxes. Before the vessel leaves, you can find some more secret ones. Good luck!</div></>
                    )}
                  <div className='px-8 grid-cols-4 grid mt-4 mb-6 lg:text-sm xl:text-md'>
                    {isClaimAirdrop ? (<div className='py-4'><span className='mb-1 flex items-center'><FaCircleCheck size="14" className='me-1 text-emerald-500' />Taiko Airdrop</span><img src="../img/taiko-element-box-simple.png" className='lg:w-[80px] xl:w-[120px] h-fit rounded-2xl' /></div>) : null}
                    {isClaimCinematic ? (<div className='py-4'><span className='mb-1 flex items-center'><FaCircleCheck size="14" className='me-1 text-emerald-500' />Cinematic</span><img src="../img/taiko-element-box-cinematic.png" className='lg:w-[80px] xl:w-[120px] h-fit rounded-2xl' /></div>) : null}
                    {isClaimTaikoBalance ? (<div className='py-4'><span className='mb-1 flex items-center'><FaCircleCheck size="14" className='me-1 text-emerald-500' />Taiko Balance</span><img src="../img/taiko-element-box-taikobalance.png" className='lg:w-[80px] xl:w-[120px] h-fit rounded-2xl' /></div>) : null}
                    {isClaimTaikoons ? (<div className='py-4'><span className='mb-1 flex items-center'><FaCircleCheck size="14" className='me-1 text-emerald-500' />Taikoons</span><img src="../img/taiko-element-box-taikoons.png" className='lg:w-[80px] xl:w-[120px] h-fit rounded-2xl' /></div>) : null}
                    {isClaimFarcaster ? (<div className='py-4'><span className='mb-1 flex items-center'><FaCircleCheck size="14" className='me-1 text-emerald-500' />Farcaster</span><img src="../img/taiko-element-box-farcaster.png" className='lg:w-[80px] xl:w-[120px] h-fit rounded-2xl' /></div>) : null}
                    {isClaimTxCount ? (<div className='py-4'><span className='mb-1 flex items-center'><FaCircleCheck size="14" className='me-1 text-emerald-500' />Secret</span><img src="../img/taiko-element-box-tx.png" className='lg:w-[80px] xl:w-[120px] h-fit rounded-2xl' /></div>) : null}
                    {isClaim1Eth ? (<div className='py-4'><span className='mb-1 flex items-center'><FaCircleCheck size="14" className='me-1 text-emerald-500' />Secret</span><img src="../img/taiko-element-box-etherbalance.png" className='lg:w-[80px] xl:w-[120px] h-fit rounded-2xl' /></div>) : null}
                  </div>
                </div>) : null}

              {isVesselDone ? (
                <div id="end block" className='absolute lg:top-[2770px] lg:start-[320px] xl:top-[3600px] xl:start-[520px] bg-white/90 cursor-default drop-shadow-md rounded-2xl opacity-0 hover:opacity-100 ease-in-out duration-300 lg:w-[400px] xl:w-[400px] h-fit'>
                  <div className='flex w-full mt-6'>
                    <div className='flex w-1/3 ms-6'>
                      <img src="../img/taikotrailipfs.jpg" className='lg:w-[120px] xl:w-[150px] h-fit rounded-2xl' />
                    </div>
                    <div className='flex w-2/3 lg:text-sm xl:text-md text-black justify-start px-6'>
                      Thank you, stranger, for helping our old farmer. The villagers appreciate it, and we would be proud if you get this NFT as proof of your use of Taiko Network.
                    </div>
                  </div>
                  <div className='flex w-full my-6'>
                    {!address ? (
                      <>
                        <button onClick={() => open()} id="end button" className=' bg-black hover:bg-cyan-300 hover:text-black/80 ease-in-out duration-300 text-white w-full py-1.5 mx-4 rounded-lg justify-center'>Mint</button>
                      </>
                    ) : !isMinted && !isMinting ? (
                      <><button onClick={submitTaiko} id="end button" className=' bg-black hover:bg-cyan-300 hover:text-black/80 ease-in-out duration-300 text-white w-full py-1.5 mx-4 rounded-lg justify-center'>Mint</button>
                      </>
                    ) : !isMinted && isMinting ? (
                      <><button disabled={isMinting} id="end button" className=' bg-black/10 text-black/20 w-full py-1.5 mx-4 rounded-lg justify-center'>Minting</button>
                      </>)
                      : (
                        <><button disabled={true} id="end button" className=' bg-black/10 text-black/20 w-full py-1.5 mx-4 rounded-lg items-center justify-center flex'><FaCircleCheck size="14" className='me-1 text-black/15' />Minted</button>
                        </>
                      )
                    }
                  </div>
                </div>) : null}


              <img src="../img/taikotrail.jpg" className='w-full justify-center' />
            </div>
          </div>
        </div>) : null}
    </div >
  )
}

export default TaikoTrail