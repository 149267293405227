import React, { useState, useEffect } from 'react'
import { FaCheckCircle, FaSort, FaEthereum } from "react-icons/fa"
import { MdCircle, MdOutlineOpenInNew } from "react-icons/md";
import { CgSpinner } from "react-icons/cg";
import { Link } from 'react-router-dom';
import { useParams, useNavigate } from 'react-router-dom';
import { ethers } from 'ethers';
import { BrowserProvider, parseUnits } from "ethers";
import Categories from '../components/Categories';
import { useWeb3ModalProvider, useWeb3ModalAccount, useWeb3Modal } from '@web3modal/ethers/react'
// ABI
import NFT from '../NFT.json'
import itemsjson from '../items.json';
import { ETHRATE } from '../config';

const ItemPage = ({ loadBlockchainData, web3Handler, account, provider, setProvider, network, setNetwork }) => {
    const { category, contract } = useParams();  // Destructure the parameters from the URL
    //const [items, setItems] = useState([]);
    //const [item, setItem] = useState(null);
    //const items = itemsjson
    const { open } = useWeb3Modal()
    const { address, chainId, isConnected } = useWeb3ModalAccount()
    const { walletProvider } = useWeb3ModalProvider()

    const item = itemsjson.find(item => item.category === category && item.contract === contract);
    const [nft, setNFT] = useState(null);
    const [txHash, setTXHASH] = useState(null);
    const [isWaiting, setIsWaiting] = useState(false);
    const [isMinting, setIsMinting] = useState(false);
    const [isMinted, setIsMinted] = useState(false);
    const [isMintingDone, setIsMintingDone] = useState(true);
    const [totalSupply, setTotalSupply] = useState(null);
    const ipfs = `https://ipfs.io/ipfs/${item.ipfs}`

    useEffect(() => {
        if (item) {
            checkMintStatus();
            totalMinted();
        }
    }, [item]);

    if (!item) {
        return <div>Loading...</div>;
    }



    const urlRpc = {
        ethereum: 'https://ethereum-rpc.publicnode.com',
        blast: 'https://rpc.blast.io',
        base: 'https://mainnet.base.org',
        zora: 'https://rpc.zora.energy',
        mode: 'https://mainnet.mode.network',
        linea: 'https://rpc.linea.build',
        scroll: 'https://scroll-mainnet.public.blastapi.io',
        taiko: 'https://rpc.mainnet.taiko.xyz',
        berachain: 'https://artio.rpc.berachain.com'
    }

    const feeChains = {
        ethereum: 0,
        blast: 0.000075,
        base: 0.000033,
        linea: 0.000033,
        zora: 0.000033,
        mode: 0.000033,
        scroll: 0.000033,
        taiko: 0.000033,
        berachain: 0.000033
    }

    const colorChain = {
        ethereum: '#636363',
        blast: '#ffd60a',
        base: '#1982c4',
        linea: '#1e1b18',
        zora: '#6d597a',
        mode: '#80b918',
        scroll: '#ffdeb5',
        taiko: '#ff758f',
        berachain: '#6c584c'
    }

    const chainCode = {
        ethereum: '1n',
        blast: '81457n',
        base: '8453n',
        linea: '59144n',
        zora: '7777777n',
        mode: '34443n',
        scroll: '534352n',
        taiko: '167000n',
        berachain: '80085n'
    }

    const priceNFT = item.price + feeChains[category];

    const targetChain = {
        ethereum: {
            chainName: 'Ethereum Mainnet',
            chainId: '0x1',
            nativeCurrency: { name: 'ETH', decimals: 18, symbol: 'ETH' },
            rpcUrls: [urlRpc.ethereum],
            blockExplorerUrls: ['https://etherscan.io']
        },
        blast: {
            chainName: 'Blast',
            chainId: '0x13e31',
            nativeCurrency: { name: 'ETH', decimals: 18, symbol: 'ETH' },
            rpcUrls: ['https://rpc.blast.io'],
            blockExplorerUrls: ['https://blastscan.io']
        },
        base: {
            chainName: 'Base',
            chainId: '0x2105',
            nativeCurrency: { name: 'ETH', decimals: 18, symbol: 'ETH' },
            rpcUrls: ['https://mainnet.base.org'],
            blockExplorerUrls: ['https://basescan.org']
        },
        zora: {
            chainName: 'Zora',
            chainId: '0x76adf1',
            nativeCurrency: { name: 'ETH', decimals: 18, symbol: 'ETH' },
            rpcUrls: ['https://rpc.zora.energy'],
            blockExplorerUrls: ['https://explorer.zora.energy']
        },
        mode: {
            chainName: 'Mode',
            chainId: '0x868b',
            nativeCurrency: { name: 'ETH', decimals: 18, symbol: 'ETH' },
            rpcUrls: ['https://mainnet.mode.network'],
            blockExplorerUrls: ['https://explorer.mode.network']
        },
        linea: {
            chainName: 'Linea',
            chainId: '0xe708',
            nativeCurrency: { name: 'ETH', decimals: 18, symbol: 'ETH' },
            rpcUrls: ['https://rpc.linea.build'],
            blockExplorerUrls: ['https://lineascan.build']
        },
        scroll: {
            chainName: 'Scroll',
            chainId: '0x82750',
            nativeCurrency: { name: 'ETH', decimals: 18, symbol: 'ETH' },
            rpcUrls: ['https://rpc.scroll.io'],
            blockExplorerUrls: ['https://scrollscan.com']
        },
        taiko: {
            chainName: 'Taiko',
            chainId: '0x28c58',
            nativeCurrency: { name: 'ETH', decimals: 18, symbol: 'ETH' },
            rpcUrls: ['https://rpc.mainnet.taiko.xyz'],
            blockExplorerUrls: ['https://taikoscan.io']
        },
        berachain: {
            chainName: 'Berachain Artio',
            chainId: '0x138d5',
            nativeCurrency: { name: 'BERA', decimals: 18, symbol: 'BERA' },
            rpcUrls: ['https://artio.rpc.berachain.com'],
            blockExplorerUrls: ['https://artio.beratrail.io']
        }
    };


    //MINT NFT
    const submitHandler = async (e) => {
        e.preventDefault();

        setIsMinting(true);
        setIsMintingDone(false);
        //console.log(network)
        //if (chainId !== chainCode[category]) {
        //if (network && network.chainId !== chainCode[category]) {
        //    await changeNetworkLinea();
        //}


       // if (!address) {
       //     await open();
       // }


        const provider = new ethers.BrowserProvider(walletProvider);
        setProvider(provider);
        const network = await provider.getNetwork();
        const currentChainId = parseInt(network.chainId, 16);

        if (currentChainId !== chainCode[category]) {
            const networkChanged = await changeNetwork(category);
            if (!networkChanged) {
                console.error("Network change required to continue. Please switch to the correct network.");
                setIsMinting(false);
                setIsMintingDone(false);
                return;
            }
        }


        setIsMinting(true);
        try {
            // Mint NFT
            const txHash = await mintImage()
            setIsMinting(false);
            setIsWaiting(false);
            setIsMintingDone(true);
            //console.log(txHash);
            //navigate(`/mint/done?txHash=${txHash}`)
            checkMintStatus();
        }
        catch (e) {
            console.error(e);
            setIsMinting(false);
            setIsWaiting(false);
            setIsMintingDone(false);
            // Handle error appropriately, e.g., display an error message to the user
        }
        return

    }

    //Mint NFT
    const mintImage = async () => {

        try {
            const provider = new ethers.BrowserProvider(walletProvider);
            setProvider(provider)
            //console.log(provider)
            const network = await provider.getNetwork()
            const nft = new ethers.Contract(item.contract, NFT, provider);
            setNFT(nft);
            setIsWaiting(true);
            const signer = await provider.getSigner()
            //console.log(signer)
            // here stops
            const transaction = await nft.connect(signer).mint(ipfs, { value: ethers.parseUnits(`${priceNFT}`, "ether") })
            await transaction.wait()

            const txHash = transaction.hash;
            setTXHASH(txHash)

            return txHash
        }
        catch (e) {
            console.error(e);
            return null;
        }
    }

    const changeNetwork = async (category) => {
        const params = [targetChain[category]];
        try {
            await window.ethereum.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: params[0].chainId }]
            });
            return true;
        } catch (err) {
            if (err.code === 4902) {
                try {
                    await window.ethereum.request({
                        method: "wallet_addEthereumChain",
                        params
                    });
                    return true;
                } catch (error) {
                    console.error(error);
                    return false;
                }
            } else {
                console.error(err);
                return false;
            }
        }
    };


    //Change network if needed on Linea
    const changeNetworkLinea = async (e) => {
        //e.preventDefault()
        //console.log(network)
        //if (chainId !== chainCode[category]) {
        if (network && network.chainId !== chainCode[category]) {
            let params = [];

            switch (category) {
                case 'ethereum':
                case 'blast':
                case 'base':
                case 'linea':
                case 'zora':
                case 'mode':
                case 'scroll':
                case 'taiko':
                case 'berachain':
                    params.push(targetChain[category]);
                    break;
                default:
                    break;
            }

            if (params.length > 0) {
                try {
                    await window.ethereum.request({
                        method: "wallet_switchEthereumChain",
                        params: [{ chainId: params[0].chainId }]
                    });
                } catch (err) {
                    console.log(err);
                    if (err.code === 4902) {
                        await window.ethereum.request({
                            method: 'wallet_addEthereumChain',
                            params: [params[0]]
                        });
                    }
                }
            }
            console.error();
            setNetwork({
                chainId: params[0].chainId,
                chainName: params[0].chainName
            });
        }
    }

    const checkMintStatus = async () => {
        try {
            //const provider = new ethers.BrowserProvider(window.ethereum);
            const provider = new ethers.JsonRpcProvider(urlRpc[category]);
            //console.log(provider)
            const nftContract = new ethers.Contract(contract, NFT, provider);
            //console.log(nftContract)
            setNetwork(chainCode[category]);
            const hasMinted = await nftContract.hasMinted(address);
            setIsMinted(hasMinted);
            //console.log(hasMinted);
        }
        catch (e) {
            console.error(e);
        }
    };

    const totalMinted = async () => {
        try {
            //const provider = new ethers.BrowserProvider(window.ethereum);
            const provider = new ethers.JsonRpcProvider(urlRpc[category]);
            //console.log(provider)
            setNetwork(item.chainID);
            const nftContract = new ethers.Contract(contract, NFT, provider);
            //console.log(nftContract)
            const total = await nftContract.totalSupply();
            //console.log(total);
            setTotalSupply(total.toString());
            //console.log(total);
            return total.toString();
        }
        catch (e) {
            console.error(e);
            return 0;
        }
    };




    return (
        <div className='w-full flex py-10 mb-8 lg:mb-14 items-center justify-center'>
            <div className='flex-row justify-between w-full max-w-[1100px] items-start'>
                <div className='flex justify-center'>
                    <Categories />
                </div>

                <div className='flex-row md:flex justify-center mt-10 md:px-4 lg:px-0'>

                    <section id="left" className='flex w-full md:w-1/2 justify-center'>
                        <div className="lg:w-[500px] lg:h-[500px]">
                            <img
                                src={`../img/${item.img}.jpg`}
                               
                            />
                        </div>
                    </section>

                    <section id="right" className='flex-row w-[95%] md:w-fit items-start mx-4 md:mx-0 mt-6 md:mt-0 justify-start md:ms-10 text-black/85'>
                        <div className='flex justify-start mb-5 text-4xl font-medium'>{item.title}</div>
                        <div className='flex my-2 justify-start text-sm'><span className='text-black/30 me-2'>supply: </span> {item.limited === true ? (`${item.howmany}`) : 'unlimited'}</div>
                        <div className='flex my-2 justify-start text-sm items-center'>
                            <div className='text-black/30 me-2 flex'>chain: </div>
                            <div className='flex me-1'><MdCircle size="12" style={{ color: colorChain[category] }} /></div>
                            <div className='flex'>{item.category}</div>
                        </div>
                        <div className='flex my-2 justify-start text-sm'><span className='text-black/30 me-2'>token: </span> ERC-721</div>
                        <div className='flex flex-wrap md:flex-nowrap my-2 justify-start items-center text-sm'>
                            <div className='text-black/30 me-2 flex'>contract: </div>
                            <div className='flex me-1 truncate'>{item.contract}</div>
                            <div className='flex'><Link target='_blank' to={`${targetChain[category].blockExplorerUrls[0]}/address/${item.contract}`}><MdOutlineOpenInNew size="12" className='text-black/30' /></Link></div>
                        </div>
                        <div className='flex my-2 justify-start text-sm'><span className='text-black/30 me-2'>total minted: </span> {totalSupply}</div>
                        <div className='flex my-2 justify-start text-sm items-center pb-8 border-b border-b-black/30'>
                            <div className='flex text-black/30 me-2'>status: </div>
                            {isMinted === true ? (<><div className='flex'><FaCheckCircle size="12" className='me-1 text-green-400' /></div>
                                <div className='flex'>already minted</div></>) : (<div className='flex'>not minted</div>)}
                        </div>

                        <div className='flex-row w-full justify-start items-center pt-2'>

                            <div id="price" className='flex items-center justify-start text-sm'>
                                <div className='grid grid-cols-3 gap-0.5 w-fit'>

                                    <div className='text-black/30 me-1 justify-start'>price: </div>
                                    <div className=' text-black/50 px-2 items-center justify-start'>
                                        {item.price === 0 ? '0.000 ETH' : (`${item.price} ETH`)}</div>
                                    <div className='text-black/50 justify-start'>{item.price === 0 ? '~ $0.00' : `~ $${(item.price * ETHRATE).toFixed(2)}`}</div>


                                    <div className='text-black/30 me-1 justify-start'>protocol fee: </div>
                                    <div className=' text-black/50 px-2 items-center justify-start'>
                                        {item.category === 'ethereum' ? '0.000 ETH' : `${feeChains[category]} ETH`}</div>
                                    <div className='text-black/50 justify-start'>
                                        {item.category === 'ethereum' ? '~ $0.00' : `~ $${(feeChains[category] * ETHRATE).toFixed(2)}`}
                                    </div>



                                    <div className='text-black me-1 justify-start mt-2'>total: </div>
                                    <div className=' text-black px-2 items-center justify-start  mt-2'>
                                        {item.category === 'ethereum' && item.price === 0 ? '0.000 ETH' : item.category === 'ethereum' && item.price !== 0 ? `${item.price} ETH` : `${(parseFloat(item.price) + feeChains[category]).toFixed(6)} ETH`}</div>
                                    <div className='text-black justify-start  mt-2'>
                                        {item.category === 'ethereum' && item.price === 0 ? '~ $0.00' : item.category === 'ethereum' && item.price !== 0 ? `~ $${(item.price * ETHRATE).toFixed(2)}` : `~ $${(parseFloat(item.price) * ETHRATE + (feeChains[category] * ETHRATE)).toFixed(2)}`}
                                    </div>
                                </div>
                            </div>



                        </div>
                        {!address ? 
                                (<><button onClick={() => open()} disabled={isMinting} className='bg-black/80 text-white text-md md:px-20 px-12 py-2 rounded-xl mt-10 hover:bg-black hover:ease-in-out cursor-pointer w-[95%] md:w-full  ease-in-out duration-300 '>Mint</button></>)
                                                    : (!isMinting ?
                            (<><button onClick={submitHandler} disabled={isMinting} className='bg-black/80 text-white text-md md:px-20 px-12 py-2 rounded-xl mt-10 hover:bg-black hover:ease-in-out cursor-pointer w-[95%] md:w-full  ease-in-out duration-300 '>Mint</button></>)
                            : (
                                <div className='bg-black/20 flex text-black/50 text-md px-20 py-2 rounded-xl mt-10 cursor-default w-full justify-center ease-in-out'>
                                    <CgSpinner className='animate-spin flex h-6 w-6 justify-center items-center' /></div>
                            ))
                        }


                        {isWaiting ? (
                            <>
                                <div className="animate-pulse flex space-x-4 mt-5 mx-1 ease-in-out">
                                    <div className="flex-1 space-y-6 py-1">

                                        <div className="space-y-3">
                                            <div className="grid grid-cols-3 gap-4">
                                                <div className="h-2 bg-black/20 rounded col-span-2"></div>
                                            </div>
                                            <div className="h-2 bg-black/20 rounded"></div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                            : !isWaiting && isMintingDone && txHash !== null ?
                                (
                                    <><div className=' flex text-black/50 text-md rounded-xl mt-5 cursor-default w-full items-center justify-start ease-in-out'>
                                        <Link target='_blank' className='flex items-center' to={`${targetChain[category].blockExplorerUrls[0]}/tx/${txHash}`}>
                                            <div className='flex'><FaCheckCircle size="14" className='me-1 text-green-400' /></div>
                                            <div className='flex me-1'>Done! TxHash</div>
                                            <div className='flex'><MdOutlineOpenInNew size="14" className='text-black/50 items-center' /></div>
                                        </Link></div></>
                                )
                                : null}


                    </section>
                </div>
            </div>
        </div>
    )
}


export default ItemPage;