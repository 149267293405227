import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export class Categories extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categories: [
                //{
                //    key: 'ethereum',
                //    name: 'Ethereum',
                //    color: '#636363'
                //},
                //{
                //    key: 'blast',
                //    name: 'Blast',
                //    color: '#ffd60a'
                //},
                {
                    key: 'base',
                    name: 'Base',
                    color: '#1982c4'
                },
                {
                    key: 'zora',
                    name: 'Zora',
                    color: '#6d597a'
                },
                {
                    key: 'mode',
                    name: 'Mode',
                    color: '#80b918'
                },
                {
                    key: 'linea',
                    name: 'Linea',
                    color: '#1e1b18'
                },
                {
                    key: 'scroll',
                    name: 'Scroll',
                    color: '#ffdeb5'
                },
                {
                    key: 'taiko',
                    name: 'Taiko',
                    color: '#ff758f'
                }//,
                //{
                //    key: '|',
                //    name: '|',
                //    color: ''
                //},
                //{
                //    key: 'berachain',
                //    name: 'Berachain',
                //    color: '#6c584c'
                //}//,
                //{
                //    key: 'fuel',
                //    name: 'Fuel',
                //    color: '#8cb369'
                //}
            ]
        }
    }
  render() {
    return (
        <div id='chains' className='flex flex-wrap mb-6 lg:mb-12 ms-4 md:ms-0 items-center justify-start md:justify-center text-sm'>

      {this.state.categories.map(el => (
        [el.name === "|" ? <div className='hidden md:flex w-fit px-2 py-0.5 text-xs md:text-sm text-black/20 md:mx-4 cursor-pointer'>|</div> 
        : <Link 
        to={`/${el.key}`}
        className={`flex w-fit px-2 py-0.5 border-b-2 my-4 text-sm cursor-pointer ${el.key === 'blast' ? 'hover:text-[#fcf300] border-[#fcf300] hover:bg-black' : el.key === 'scroll' ? 'hover:text-black border-[#ffdeb5] hover:bg-[#ffdeb5]' : `border-[${el.color}] hover:bg-[${el.color}] hover:text-white`} text-black mx-4 ease-in-out duration-300`}        
        key={el.key}>{el.name}</Link>]
      ))}

      </div>
    )
  }
}

export default Categories