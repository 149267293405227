import React from 'react'
import { useState, useEffect } from 'react';
import './App.css'
import { Route, Routes, Switch, Redirect, Router } from "react-router-dom"
import { Suspense } from 'react'
import ItemPage from './pages/ItemPage'
import Head from './components/Head'
import Home from './components/Home'
import Basement from './components/Basement'
import NotFound from './pages/NotFound'
import CategoryPage from './pages/CategoryPage'
import TermsConditions from './pages/TermsConditions'
import itemsjson from './items.json';
import { useParams } from 'react-router-dom';
import { ethers } from "ethers";
import Item from './components/Item';
import { createWeb3Modal, defaultConfig, useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react'
import TaikoTrail from './pages/TaikoTrail';



function App() {

  const [account, setAccount] = useState("")
  const [provider, setProvider] = useState(null)
  const [network, setNetwork] = useState(null)
  
  const projectId = process.env.REACT_APP_PROJECT_ID

  const walletMainnet = {
    chainId: 1,
    name: 'Ethereum',
    currency: 'ETH',
    explorerUrl: 'https://etherscan.io',
    rpcUrl: 'https://cloudflare-eth.com'
  }
  const walletBase = {
    chainId: 8453,
    name: 'Base',
    currency: 'ETH',
    explorerUrl: 'https://basescan.org',
    rpcUrl: 'https://mainnet.base.org'
  }
  const walletBlast = {
    chainId: 81457,
    name: 'Blast',
    currency: 'ETH',
    explorerUrl: 'https://blastscan.io',
    rpcUrl: 'https://rpc.blast.io'
  }
  const walletMode = {
    chainId: 34443,
    name: 'Mode',
    currency: 'ETH',
    explorerUrl: 'https://explorer.mode.network',
    rpcUrl: 'https://mainnet.mode.network'
  }
  const walletZora = {
    chainId: 7777777,
    name: 'Zora',
    currency: 'ETH',
    explorerUrl: 'https://explorer.zora.energy',
    rpcUrl: 'https://rpc.zora.energy'
  }
  const walletLinea = {
    chainId: 59144,
    name: 'Linea',
    currency: 'ETH',
    explorerUrl: 'https://lineascan.build',
    rpcUrl: 'https://rpc.linea.build'
  }
  const walletScroll = {
    chainId: 534352,
    name: 'Scroll',
    currency: 'ETH',
    explorerUrl: 'https://scrollscan.com',
    rpcUrl: 'https://1rpc.io/scroll'
  }
  const walletTaiko = {
    chainId: 167000,
    name: 'Taiko',
    currency: 'ETH',
    explorerUrl: 'https://taikoscan.io',
    rpcUrl: 'https://rpc.mainnet.taiko.xyz'
  }
  const walletBera = {
    chainId: 80085,
    name: 'Berachain Artio',
    currency: 'BERA',
    explorerUrl: 'https://artio.beratrail.io',
    rpcUrl: 'https://artio.rpc.berachain.com'
  }

  const metadata = {
    name: 'Cinematic',
    description: 'Cinematic',
    url: 'https://cinematiclabs.xyz', // url must match your domain & subdomain
    icons: ['']
  }

  const ethersConfig = defaultConfig({
    /*Required*/
    metadata
  })

  const modal = createWeb3Modal({
    ethersConfig,
    chains: [walletMainnet, walletLinea, walletBase, walletZora, walletBlast, walletScroll, walletMode, walletTaiko],
    projectId,
    chainImages: {
      59144: 'https://pbs.twimg.com/profile_images/1639402103486521344/erDLnbwE_400x400.jpg',
      8453: 'https://images.mirror-media.xyz/publication-images/cgqxxPdUFBDjgKna_dDir.png',
      81457: 'https://pbs.twimg.com/profile_images/1726739354700173312/OeT9Ef1J_400x400.jpg',
      34443: 'https://pbs.twimg.com/profile_images/1688569679877390338/IYXD4bdy_400x400.jpg',
      7777777: 'https://pbs.twimg.com/profile_images/1741494128779886592/RY4V0T2F_400x400.jpg',
      534352: 'https://pbs.twimg.com/profile_images/1696531511519150080/Fq5O0LeN_400x400.jpg',
      167000: 'https://bridge.taiko.xyz/chains/taiko.svg',
      80085: 'https://www.berachain.com/icon.svg'
    },
    allowUnsupportedChain: true
  })

  const { address, chainId, isConnected } = useWeb3ModalAccount()
  const { walletProvider } = useWeb3ModalProvider()

  // Load Blockchain Data
  const loadBlockchainData = async () => { // !!!!!!!!!!!! make it non-async !!!!!!!!!!!!!!! 
    if (!walletProvider) {
      console.error("Wallet provider is not available.");
      return;
    }
    
    try {
      const provider = new ethers.BrowserProvider(walletProvider);
      setProvider(provider)
      const network = await provider.getNetwork()
      setNetwork(network);
    }
    catch (e) {
      console.error(e);
      console.log(e)
    }
  }

  // Wallet Connect
  const web3Handler = async () => {
    if (window.ethereum) {
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((account) => {
          setAccount(account[0]);
          getNetwork(account[0]);
        })
        .catch((error) => {
          // If the request fails, the Promise rejects with an error.
          window.location.reload();
        });
      window.ethereum.on("accountChanged", web3Handler);
      window.ethereum.on("chainChanged", handleChainChanged);
    } else {
      alert("install metamask");
    };
}


  // Changed chain
  const handleChainChanged = async () => {
    loadBlockchainData(); // Reload blockchain data on chain change
  };

  useEffect(() => {
    loadBlockchainData();
  }, [walletProvider]);


const getNetwork = (account) => {
  window.ethereum.request({ method: "eth_chainId", params: [account, "latest"], })
    .then((network) => {
      setNetwork(ethers.getNumber(network));
    })
    .catch((error) => {
      console.error("Error getting network:", error);
    });

  loadBlockchainData()
}



  function CategoryPageWrapper() {
    const { category } = useParams();
    return <CategoryPage category={category} items={itemsjson} account={account} setNetwork={setNetwork} />;
  }

  return (
        <>
        <Head web3Handler={web3Handler} account={account} loadBlockchainData={loadBlockchainData} address={address} />

        <Routes>
          <Route path="/" element={<Home account={account} setNetwork={setNetwork} />} />
          <Route path="/:category" element={<CategoryPageWrapper account={account} setNetwork={setNetwork} />}/>
          <Route path="/:category/:contract" element={<ItemPage loadBlockchainData={loadBlockchainData} network={network} setNetwork={setNetwork} provider={provider} setProvider={setProvider} account={account} web3Handler={web3Handler}  />} />
          <Route path='*' element={<NotFound/>}/>
          <Route path='/terms' element={<TermsConditions />} />
          <Route path='/taiko/trail' element={<TaikoTrail loadBlockchainData={loadBlockchainData} network={network} setNetwork={setNetwork} provider={provider} setProvider={setProvider} account={account} web3Handler={web3Handler} />} />
        </Routes>

        <Basement />
        </>
  );
}

export default App;
