import React from 'react'
import Chains from "./Chains"
import { MdCircle } from "react-icons/md";
import { FaCheckCircle, FaSort, FaEthereum } from "react-icons/fa";
import ItemsMain from './ItemsMain';
import Categories from './Categories';

import itemsjson from '../items.json'

class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentItems: [],
      items: itemsjson,
    }

    this.state.currentItems = this.state.items
    this.chooseCategory = this.chooseCategory.bind(this)
  }
  render() {
    //const { loadBlockchainData, web3Handler, account, provider, setProvider, network, setNetwork } = this.props;

    return (
      <div className='w-full flex py-10 items-center justify-center'>
        <div className='flex-row w-full max-w-[1200px] items-center'>
          <Categories />
          <div id='body' className='flex-row w-full justify-center items-start'>
            <div className='flex w-full text-5xl md:text-6xl font-bold justify-center px-6 md:py-2 md:px-2 mb-6'>
              <span className='bg-clip-text text-transparent bg-gradient-to-r from-cyan-300 to-violet-300 leading-relaxed'>
                Every frame is a story</span></div>
            <div className='flex w-full text-sm justify-start items-center ms-4 mb-5 text-black/20 cursor-default'>
              Sort by: Newest<FaSort size="10" className='ms-1'></FaSort></div>
            <div id='scheme' className='flex justify-center items-start w-full'>
              <ItemsMain items={this.state.currentItems} account={this.props.account}></ItemsMain>


            </div>
          </div>
        </div>
      </div>
    )
  }

  chooseCategory(category) {
    this.setState({
      currentItems: this.state.items.filter(el => el.category === category)
    })
  }

}
export default Home