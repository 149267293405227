import React from 'react';
import ItemsMain from '../components/ItemsMain';
import { useParams } from 'react-router-dom';
import Categories from '../components/Categories';
import { FaCheckCircle, FaSort, FaEthereum } from "react-icons/fa";

class CategoryPage extends React.Component {
    render() {
        const { category, items } = this.props;
        const filteredItems = items.filter(item => item.category === category);
        //const { loadBlockchainData, web3Handler, account, provider, setProvider, network, setNetwork } = this.props;
        return (
            <div className='w-full flex py-10 items-center justify-center'>
                <div className='flex-row w-full max-w-[1200px] items-center'>
                    <Categories />
                    <div id='body' className='flex-row w-full justify-center items-start'>
                        <div className='flex w-full text-3xl justify-center mb-2'>
                            <h1 className=' first-letter:uppercase'>{category}</h1></div>
                        <div className='flex w-full text-sm justify-start items-center ms-4 mb-5 text-black/20 cursor-default'>
                            Sort by: Newest<FaSort size="10" className='ms-1'></FaSort></div>
                        <div id='scheme' className='flex justify-start items-start w-full'>
                            <ItemsMain items={filteredItems} />
                        </div>
                    </div>
                </div>
            </div>



        );
    }
}

export default CategoryPage;
