import React, { Component, useState, useEffect } from 'react'
import { FaCheckCircle, FaSort, FaEthereum } from "react-icons/fa"
import { MdCircle } from "react-icons/md";
import { Link } from 'react-router-dom';
import { ethers } from 'ethers';
import NFT from '../NFT.json'

export class Item extends Component {

  constructor(props) {
    super(props);
  }

  //checkMintStatus = async () => {
    
    //try {
      //const provider = new ethers.JsonRpcProvider('https://sepolia.blast.io');
      //const nftContract = new ethers.Contract(this.props.item.contract, NFT, provider);
      //console.log(nftContract);
      //console.log(this.props.account);
      //this.props.setNetwork(this.props.item.chainID);
      //const hasMinted = await nftContract.hasMinted(this.props.account);
      //console.log(hasMinted);
      //this.setState({ isMinted: hasMinted });
    //} catch (e) {
      //console.error(e);
    //}
  //};

  //componentDidMount() {
  //  this.checkMintStatus();
  //}

  render() {

    const colorChain = {
      ethereum: '#636363',
      blast: '#ffd60a',
      base: '#1982c4',
      linea: '#1e1b18',
      zora: '#6d597a',
      mode: '#80b918',
      scroll: '#ffdeb5',
      taiko: '#ff758f',
      berachain: '#6c584c'
  }
  

    return (


      <div id='nft' className='flex-row w-[44%] md:w-[30%] lg:w-[22%] mt-2 mb-6 mx-2 md:mx-3 lg:max-w-[310px] cursor-pointer'>
        <Link className=' cursor-pointer' to={`../${this.props.item.category}/${this.props.item.contract}`}>
          <div
            className="cursor-pointer"
            >
           <img src={`../img/${this.props.item.img}.jpg`} className='w-fit cursor-pointer'/>
          </div>
          <div className='flex w-full justify-between m-0 items-center'>
            <div className='flex justify-start text-md my-1 truncate'>{this.props.item.title}</div>
            {this.props.item.limited === true ?
              <div className='flex justify-end text-xs bg-black text-white w-fit px-1 py-0.25 ms-2 items-center'>limited</div>
              : null}
          </div>


          {this.props.isMinted === true ? (<div className='flex justify-start items-center'><div className='flex justify-start text-xs bg-black/5 text-black/40 w-fit px-1 py-0.25 rounded-lg items-center'>
            <FaCheckCircle size="8" className='me-1 text-green-400'></FaCheckCircle>already minted</div></div>
          ) : null}


          <div className='flex w-full justify-between items-center mt-1 '>
            <div className='flex w-full justify-start items-center'>
              <div className='flex me-1'><MdCircle size="12" style={{ color: colorChain[this.props.item.category] }}></MdCircle></div>
              <div className='flex justify-start items-center text-xs text-black/50 truncate'>{this.props.item.contract.slice(0, 12)}..</div>
            </div>
            <div className='flex items-center justify-end'>
              {this.props.item.price != 0 ?
                <div className='flex text-black/80 px-2 text-xs items-center justify-center'>
                  <FaEthereum size="8" className='me-1'></FaEthereum>{this.props.item.price}</div>
                : null}
            </div>
          </div>
        </Link>
      </div>
    )
  }
}

export default Item